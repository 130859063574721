import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPen, faExclamationTriangle, faInfoCircle, faTrash, faTruck, faLink, faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PiezaIntegracionInfo from "./components/PiezaIntegracionInfo";
import InfoModal from "../../../../../../../shared/components/InfoModal";
import { CustomTooltip, getAvisoInfo, isOnVacation } from "../../../../../../../shared/helpers/functionalities";
import ExistentPresupuestosList from "../ExistentPresupuestosList";
import { checkIfExistsInOther } from "../../../../../../../shared/services/presupuestos";
import Images from "./components/Images";
import LightBoxImages from "../../../../../../../shared/components/LightBoxImages";

export default function PiezasList({
    esVenta,
    presupuesto,
    piezas,
    check,
    onAction,
    onAddedImages
}) {
    const user = JSON.parse(localStorage.getItem('user'));
    const [currentPieza, setCurrentPieza] = useState(null);
    const [existingPresus, setExistingPresus] = useState(null);
    const [imagesSRCs, setImagesSRCs] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [openInfo, setOpenInfo] = useState(false);
    const [openExisting, setOpenExisting] = useState(false);
    const [openImages, setOpenImages] = useState(false);
    const [openCarousel, setOpenCarousel] = useState(false);

    const tipoPiezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const transportistas = useSelector(state => {
        return state.transportistas
    });
    const serviciosEnvio = useSelector(state => {
        const { servicios_envio } = state;
        return servicios_envio;
    });

    useEffect(() => {
        if(check && piezas?.length > 0) {
            checkIfExistsInOtherPresupuesto();
        }
    }, [piezas, check]);

    const getDescuento = (descuentos, pieza) => {
        let precioOriginal = pieza.precio_original
        let descuento = null;

        if(pieza.con_descuento) {
            if(descuentos?.length > 0) {
                if(descuentos.length === 1) {
                    descuento = descuentos[0];
                } else {
                    descuentos = descuentos?.length > 0 ? descuentos.sort((a, b) => b.tipo_piezas?.length - a?.tipo_piezas.length) : descuentos;
                    descuento = descuentos.find(f =>{
                        let isIn = true;
        
                        if(f.tipo_piezas && f.tipo_piezas.length > 0 ) {
                            let tipo = tipoPiezas.find(tp => tp.id === pieza.tipo_pieza_id);
                            isIn = f.tipo_piezas.find(f => f === tipo.nombre);
                        }
            
                        if(f.max !== null && f.min !== null) {
                            return (parseInt(precioOriginal) <= f.max && parseInt(precioOriginal) >= f.min && isIn)
                        } else if(f.min !== null && f.max === null) {
                            return (parseInt(precioOriginal) >= f.min && isIn)
                        } else if(f.max !== null && f.min === null) {
                            return (parseInt(precioOriginal) <= f.max && isIn)
                        } else return null;
                    });
                }
            }
        }

        return descuento ? descuento.descuento : 0;
    }

    const editPieza = (pieza) => onAction('edit', pieza); 
    const duplicarPieza = (pieza) => onAction('duplicate', pieza); 
    const deletePieza = (pieza) => onAction('delete', pieza); 
    const handleOpenPiezaInfo = (pieza) => { 
       setCurrentPieza(pieza);
       setOpenInfo(true);
    }

    const handleCloseInfo = () => { setCurrentPieza(null); setOpenInfo(false); }

    const getTransportista = (transportistaId) => { return transportistas.find(t => t.id === transportistaId) }
    const getServicio = (servicioId) => { return serviciosEnvio.find(s => s.id === servicioId) }

    const getEnvio = (pieza) => {
        const envio = presupuesto?.envios?.find(e => e?.piezas.find(p => p.id === pieza.id));

        if(pieza.servicio_envio) {
            return (
                <>
                    <td colSpan={7}>
                        <div className="col-12 d-flex align-items-center">
                            <FontAwesomeIcon icon={faTruck} size="2x" color="#215732" className="me-3"></FontAwesomeIcon>

                            <div className="me-3">
                                <label htmlFor="transportista">Transportista:</label>
                                <p className="text-uppercase">{getTransportista(pieza.servicio_envio.transportista_id)?.nombre}</p>
                            </div>
                    
                            <div className="me-3">
                                <label htmlFor="servicio">Servicio:</label>
                                <p className="text-uppercase">{getServicio(pieza.servicio_envio.servicio_envio_id)?.nombre}</p>
                            </div>

                            { (envio) &&
                                <div>
                                    <label htmlFor="servicio">Albaran:</label>
                                    <p className="text-uppercase fw-bold text-success">{envio.codigo_envio}</p>
                                </div>
                            }

                            { (pieza.servicio_envio?.recogida_id) &&
                                <div className="me-3">
                                    <label htmlFor="servicio">ID de recogida:</label>
                                    <p className="text-uppercase fw-bold text-success">{pieza.servicio_envio?.recogida_id}</p>
                                </div>
                            }
                        </div>
                    </td>
                    
                    <td width="6%" className="text-center align-middle">
                        <p>
                            {parseFloat(pieza.servicio_envio.precio_transporte).toFixed(2)} €
                        </p>
                    </td>
                </>
            )
        } else {
            return (
                <td colSpan={8} className="align-middle">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545" />
                        <h3 className="ms-2">Falta asignar envío a esta pieza</h3>
                    </div>
                </td>
            )
        }
    }

    const checkIfExistsInOtherPresupuesto = async() => {
        const response = await checkIfExistsInOther({
            presupuesto_id: presupuesto?.id ? presupuesto?.id : null,
            entity_id: presupuesto?.cliente.id,
            pieza_id: presupuesto?.piezas[presupuesto?.piezas?.length - 1]?.id
        });

        if(response && response.success) {
            const existent = response.data;

            if(existent?.length > 0) {
                setExistingPresus(response.data);
                setOpenExisting(true);
            }
        }
    }
    const handleCloseExistingPresus = () => {
        setExistingPresus(null);
        setOpenExisting(false);
    }

    const handleOpenImages = (pieza) => { setCurrentPieza(pieza); setOpenImages(true); }
    const handleSavedImages = () => {
        handleCloseImages();
        onAddedImages();
    }

    const handleCloseImages = () => { setOpenImages(false); setCurrentPieza(null);  }
    const handleOpenSlider = (pieza, index) => { 
        setCurrentPieza(pieza); 
        setImagesSRCs(pieza?.fotos);
        setImageIndex(index);
        setOpenCarousel(true); 
    }
    const handleCloseCarousel = () => {
        setCurrentPieza(null);
        setImagesSRCs(null);
        setImageIndex(null);
        setOpenCarousel(false);
    }

    return (
        <div className="w-100 d-flex flex-column mt-4 mt-md-0 mt-lg-0">
            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>pieza</th>
                            <th>proveedor</th>
                            <th className="text-center">precio compra/u</th>
                            <th className="text-center">precio u</th>
                            <th className="text-center">casco</th>
                            <th className="text-center">unidades</th>
                            <th className="text-center">transporte proveedor</th>
                            <th className="text-center">acciones</th>
                        </tr>
                    </thead>

                    { piezas.map((pieza, i) => {
                        return (
                            <tbody key={'pieza-' + pieza.id}>
                                <>
                                    { (pieza.ventas?.length > 0  && pieza.ventas?.find(f => f.id !== presupuesto.id)) ?
                                        <tr  className="pieza-row">
                                            <td colSpan={8}>
                                                <span className="fw-bold mb-2">
                                                    <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                                                    &nbsp; Pieza vendida &nbsp;
                                                    <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                                                </span>
                                            </td>
                                        </tr>
                                        
                                        :
                                        (pieza.presupuestos?.length > 0 && pieza.presupuestos?.find(f => f.id !== presupuesto?.id)) ?
                                            <tr  className="pieza-row">
                                                <td colSpan={8}>
                                                    <small className="text-uppercase fw-bold mb-2">
                                                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                                                        &nbsp; Pieza añadida en otro presupuesto &nbsp;
                                                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545"></FontAwesomeIcon>
                                                    </small>
                                                </td>
                                            </tr>
                                            
                                        : ''
                                    }
                                    <tr className="pieza-row">
                                        <td>
                                            <h3 className="text-success mb-1">
                                                {pieza.nombre}

                                                <a href={pieza.url} className="text-green ms-2" target={"_blank"} rel="noreferrer">
                                                    <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                                                </a>
                                            </h3>
                                            <p className="mb-2">
                                                {pieza?.marca?.nombre} {pieza?.modelo?.nombre} {pieza?.tipo_pieza?.nombre}
                                            </p>

                                            { (tipoPiezas.find(tp => tp.id === pieza?.tipo_pieza_id)?.aviso && tipoPiezas.find(tp => tp.id === pieza?.tipo_pieza_id)?.aviso?.activo) ?
                                                <p className="mb-2 fw-bold text-uppercase text-danger">
                                                    {tipoPiezas.find(tp => tp.id === pieza?.tipo_pieza_id)?.aviso?.mensaje}
                                                </p>
                                                : ''
                                            }

                                            { pieza?.contiene_casco > 0 ?
                                                <span className="fw-bold text-uppercase highlight"><small>contiene casco</small></span>
                                                : ''
                                            }

                                            <div className="w-100 d-flex flex-wrap">
                                                <span className="table-tag mt-2">
                                                    <p> 
                                                        REF: <span className="fw-bold">{pieza.referencia_pieza}</span>
                                                    </p>
                                                </span>

                                                <p className="table-tag mx-3 mt-2">
                                                    ID: <span className="fw-bold">{pieza.id_referencia_plataforma}</span>
                                                </p>
                                            </div>

                                            {(esVenta) &&
                                                <div className="w-100 d-flex flex-column mt-2">
                                                    <p>Garantía: <span className="fw-bold text-uppercase">{pieza?.tipo_garantia?.duracion}</span></p>
                                                    <p>Motor: <span className="fw-bold text-uppercase">{pieza.km_motor} KM</span></p>
                                                </div>
                                            }

                                            { (pieza?.fotos && (pieza?.fotos !== '' && pieza.fotos !== null)) &&
                                                <div className="w-100 d-flex flex-wrap mt-2">
                                                    {pieza?.fotos.map((foto, i) => {
                                                        return (
                                                            <div className="fondo-reco">
                                                                <img 
                                                                    src={foto} 
                                                                    alt={pieza.nombre}
                                                                    style={{cursor: 'pointer'}}
                                                                    width={'auto'}
                                                                    height={'65px'}
                                                                    onClick={() => handleOpenSlider(pieza, i)}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </td>

                                        <td>
                                            <h3 className="text-success mb-1">
                                                <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + pieza.proveedor?.id} title="Ficha Proveedor" className="text-uppercase text-green mb-1">
                                                    {pieza.proveedor?.nombre}
                                                </Link>
                                            </h3>
                                            <p>{pieza.proveedor.telefono}</p>

                                            <div className="d-flex flex-wrap">
                                                { pieza.proveedor?.credito ?
                                                    <small className="fw-bold text-uppercase">A crédito</small>
                                                    : ''
                                                }
                                                {pieza.proveedor?.credito && pieza.proveedor?.integracion ? <p className="mx-2">|</p> : ''}
                                                { pieza.proveedor?.integracion ?
                                                    <small className={"fw-bold text-uppercase " + (!pieza.proveedor?.credito ? 'ms-2' : '')}>Integrado</small>
                                                    : ''
                                                }
                                            </div>

                                            <div>
                                                { pieza.con_descuento && pieza.proveedor?.descuentos && pieza.proveedor.descuentos.length > 0 ? 
                                                    <b className="text-uppercase">Descuento: {getDescuento(pieza.proveedor?.descuentos, pieza)}%</b>
                                                    : <b className="text-uppercase">Descuento: {pieza.descuento}%</b>
                                                }
                                            </div>
                                            { (isOnVacation(pieza.proveedor)) &&
                                                <div className="d-flex align-items-center text-danger fw-bold text-uppercase">
                                                    <FontAwesomeIcon icon={faUmbrellaBeach} size="1x" className="me-2" color="#dc3545" />
                                                    El proveedor esta de vacaciones
                                                </div>
                                            }

                                            { (pieza.proveedor.avisos_piezas?.length > 0) &&
                                                <div className="w-100 d-flex flex-column">
                                                    { pieza.proveedor.avisos_piezas?.filter(f => f.tipo_incidencia_id !== null)
                                                    .map(aviso => {
                                                        return (
                                                            <span className="fw-bold text-uppercase text-danger">
                                                                ¡AVISO! {aviso.porcentaje}% de incidencia de {aviso.tipo_incidencia.nombre}
                                                                <CustomTooltip
                                                                    title={
                                                                        <React.Fragment>
                                                                            <small className="m-0">{getAvisoInfo(aviso.tipo_incidencia_id)}</small>
                                                                        </React.Fragment>
                                                                    }
                                                                >
                                                                    <span className="align-self-start ms-2" style={{cursor: 'pointer'}}>
                                                                        <FontAwesomeIcon icon={faInfoCircle} size="lg" className="action" color="#dc3545" /> 
                                                                    </span>
                                                                </CustomTooltip>
                                                            </span>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </td>

                                        <td className="text-center align-middle">
                                            <p>{parseFloat(pieza.precio_compra).toFixed(2)}€</p>
                                        </td>

                                        <td className="text-center align-middle">
                                            <p>{parseFloat(pieza.precio_venta).toFixed(2)}€</p>
                                        </td>

                                        <td className="text-center align-middle">
                                            <p>{parseFloat(pieza.casco).toFixed(2)}€</p>
                                        </td>

                                        <td className="text-center align-middle">
                                            <p>
                                                x{pieza?.unidades ? pieza.unidades : (pieza?.presupuesto_relationship ? pieza.presupuesto_relationship.unidades : 1)}
                                            </p>
                                        </td>

                                        <td className="text-center align-middle">
                                            <p>
                                                {pieza.proveedor.id === 409 ?
                                                    parseFloat(pieza.servicio_envio.precio_transporte).toFixed(2) + '€'
                                                    :
                                                    parseFloat(pieza.precio_transporte).toFixed(2) + '€'
                                                }
                                            </p>
                                        </td>

                                        <td>
                                            <div className="h-100 d-flex flex-column align-items-center">
                                                { ((pieza.integracion_json !== null && pieza.integracion_json !== "")) &&
                                                    <button type="button" title="Info del desguace" className="btn btn-action mb-2" onClick={() => handleOpenPiezaInfo(pieza)}>
                                                        <FontAwesomeIcon icon={faInfoCircle} color="#62B1F6"></FontAwesomeIcon>
                                                    </button>
                                                }

                                                { (((!esVenta) || (user.rol_id === 1 || user.rol_id === 4 || user.responsable))) &&
                                                    <button type="button" title="Editar pieza" className="btn btn-action mb-2" onClick={() => editPieza(pieza)} >
                                                        <FontAwesomeIcon icon={faPen} color="#215732"></FontAwesomeIcon>
                                                    </button>
                                                }

                                                { ((!esVenta && !presupuesto.ecommerce)) &&
                                                    <button type="button" title="Duplicar pieza" className="btn btn-action mb-2" onClick={() => duplicarPieza(pieza)}>
                                                        <FontAwesomeIcon icon={faCopy} color="#215732"></FontAwesomeIcon>
                                                    </button>
                                                }

                                                { ((!esVenta && !presupuesto.ecommerce)) &&
                                                    <button type="button" title="Eliminar pieza" className="btn btn-action" onClick={() => deletePieza(pieza)}>
                                                        <FontAwesomeIcon icon={faTrash} color="#215732"></FontAwesomeIcon>
                                                    </button>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    
                                    { (pieza.observaciones_desguace) ?
                                        <tr className="pieza-row">
                                            <td colSpan={9}>
                                                <div className="w-100 form-group m-0">
                                                    <label className="fw-bold">Respuesta del desguace:</label>
                                                    <small>{pieza.observaciones_desguace}</small>
                                                </div>

                                            </td>
                                        </tr>
                                        : ''
                                    }
                                    { (pieza.observaciones) ?
                                        <tr className="pieza-row">
                                            <td colSpan={9}>
                                                <div className="w-100 form-group m-0">
                                                    <label className="fw-bold">Observaciones:</label>
                                                    <small>{pieza.observaciones}</small>
                                                </div>

                                            </td>
                                        </tr>
                                        : ''
                                    }
                                    <tr>
                                        {getEnvio(pieza)}
                                    </tr>
                                </>
                            </tbody>
                        )
                    })}
                </table>
            </div>

            { (openInfo) && 
                <InfoModal
                    width={'md'}
                    state={openInfo}
                    title="Información de la pieza de integración"
                    content={
                        <PiezaIntegracionInfo pieza={currentPieza} />
                    }
                    onClose={handleCloseInfo}
                ></InfoModal>
            }

            { (openImages) && 
                <InfoModal
                    width={'lg'}
                    state={openImages}
                    title={"Añadir imágenes de la pieza " + currentPieza.nombre}
                    content={
                        <Images
                            presupuestoId={presupuesto.id}
                            piezaId={currentPieza.id}
                            onDone={handleSavedImages}
                            onClose={handleCloseImages}
                        />
                    }
                    onClose={handleCloseImages}
                ></InfoModal>
            }

            { (openExisting) && 
                <InfoModal
                    state={openExisting}
                    title={'Presupuestos existentes'}
                    content={
                        <ExistentPresupuestosList 
                            presupuestos={existingPresus}
                        />
                    }
                    width={'md'}
                    onClose={handleCloseExistingPresus}
                ></InfoModal>
            }

            { (openCarousel) &&
                <LightBoxImages
                    images={imagesSRCs}
                    startIndex={imageIndex}
                    pieza={currentPieza}
                    fondoReco={true}
                    onClose={handleCloseCarousel}
                />
            }
        </div>
    );
}

/**
 * 
{ (!presupuesto.ecommerce && (!esVenta && presupuesto.id)) &&
                                                    <button type="button" title="Subir imágenes" className="btn btn-action mb-2" onClick={() => handleOpenImages(pieza)}>
                                                        <FontAwesomeIcon icon={faImage} color="#215732"></FontAwesomeIcon>
                                                    </button>
                                                }
 * 
 */