import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import VirtualizedAutocomplete from "../../../../../../../../../../../shared/components/Autocomplete";
import { Dialog } from "@mui/material";
import {
  checkAndCreateBloqueoLog,
  getGarantia,
} from "../../../../../../../../../../../shared/helpers/functionalities";
import InfoModal from "../../../../../../../../../../../shared/components/InfoModal";
import LightBoxImages from "../../../../../../../../../../../shared/components/LightBoxImages";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";

export default function PiezaFormBlat({
  form,
  pieza,
  proveedor,
  tarifaClienteId,
  isEditing,
  esVenta,
  onSubmitPieza,
  onChangePrecioEnvioProveedor,
  onBlockedPart,
  onOpenAvisoCliente,
}) {
  /** LocalStorage **/
  const user = JSON.parse(localStorage.getItem("user"));

  /** Redux **/
  const [isVenta] = useState(esVenta || false);
  const [servicioEnvio, setServicioEnvio] = useState(null);
  const presupuesto = useSelector((state) => {
    return state.presupuesto.entity;
  });
  const familiaPiezas = useSelector((state) => {
    const { familia_piezas } = state;
    return familia_piezas;
  });
  const tipoPiezas = useSelector((state) => {
    const { tipo_piezas } = state;
    return tipo_piezas;
  });
  const tipoGarantias = useSelector((state) => {
    const { tipo_garantias } = state;
    return tipo_garantias;
  });
  const marcas = useSelector((state) => {
    const { marcas } = state;
    return marcas;
  });
  const modelos = useSelector((state) => {
    const { modelos } = state;
    return modelos;
  });
  const tarifas = useSelector((state) => {
    const { tarifas } = state;
    return tarifas;
  });
  const tarifasEnvioProveedor = useSelector((state) => {
    const { tarifas_envio_proveedor } = state;
    return tarifas_envio_proveedor;
  });
  let timeout = null;

  /** Variables */
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
    watch,
  } = form;
  const [imagesDialogVisible, setImagesDialogVisible] = useState(false);
  const [filteredTipoPiezas, setFilteredTipoPiezas] = useState([]);
  const [filteredModelos, setFilteredModelos] = useState([]);
  const [disablePrecioOriginal, setDisablePrecioOriginal] = useState(false);
  const [disablePrecioVenta, setDisablePrecioVenta] = useState(false);
  const [showDifPrice, setShowDifPrice] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  /** Lifecycles */
  useEffect(() => {
    if (pieza) {
      setValue("modelo", pieza.modelo);
      setValue("modelo_id", pieza.modelo_id);

      if (pieza.precio_original && pieza.precio_original > 0) {
        const precios = calcularPrecios(pieza);

        setDisablePrecioOriginal(true);
        setValue("precio_compra", precios.compra);
        setValue("descuento", precios.descuento);
        setValue("tarifa_valor", precios.tarifa.valor);
        setValue("tarifa_tipo", precios.tarifa.tipo);

        proveedor.id !== 409
          ? setValue("precio_venta", precios.venta)
          : setValue("precio_venta", pieza.precio_original);
      } else {
        setDisablePrecioOriginal(false);
      }

      if (pieza.familia_tipo_pieza_id) {
        const filteredTipoPiezasByFamiliaId = tipoPiezas.filter((t) => {
          return t.familia_tipo_pieza_id === pieza.familia_tipo_pieza_id;
        });
        setFilteredTipoPiezas(filteredTipoPiezasByFamiliaId);
      } else {
        setFilteredTipoPiezas(tipoPiezas);
      }

      if (pieza.marca_id) {
        const filteredModelosByMarcaId = modelos.filter(
          (m) => m.marca_id === pieza.marca_id
        );
        setFilteredModelos(filteredModelosByMarcaId);
      } else {
        setFilteredModelos(modelos);
      }

      if (pieza?.servicio_envio) {
        setServicioEnvio(pieza?.servicio_envio ? pieza.servicio_envio : null);
      }

      if (isVenta) {
        if (presupuesto?.cliente.credito) {
          if (user.rol_id !== 1 && !user.responsable) {
            setDisablePrecioVenta(true);
          } else {
            setDisablePrecioVenta(false);
          }
        }
      } else {
        if (presupuesto?.cliente.credito) {
          if (user.rol_id !== 1 && !user.responsable) {
            setDisablePrecioVenta(true);
          } else {
            setDisablePrecioVenta(false);
          }
        }
      }
    }
  }, [pieza, proveedor]);

  useEffect(() => {
    const tipoPiezaNotInFilteredList = !filteredTipoPiezas.some(
      (t) => t.id === getValues("tipo_pieza_id")
    );
    if (filteredTipoPiezas.length > 0) {
      tipoPiezaNotInFilteredList
        ? setValue("tipo_pieza_id", filteredTipoPiezas[0].id)
        : setValue("tipo_pieza_id", getValues("tipo_pieza_id"));
      setValue("tipo_garantia_id", getGarantia(getValues("tipo_pieza_id")));
      setValue(
        "precio_transporte",
        calcularPrecioEnvio(getValues("tipo_pieza_id"))
      );
    }
  }, [filteredTipoPiezas]);

  /** Methods */
  const handleClickViewImages = (value) => {
    setImagesDialogVisible(value);
  };

  const checkIfContieneCascoObligatio = () => {
    if (
      getValues("tipo_pieza_id") === 406 &&
      getValues("precio_original") >= 2000
    ) {
      setValue("contiene_casco", "true");
      setValue("casco", 0);
      onOpenAvisoCliente();
    }
  };

  const handleDownloadImages = async () => {
    const downloadImage = async (url, index) => {
      const tipoPieza = getValues("tipo_pieza_id");
      const nombreTipoPieza =
        tipoPiezas.find((t) => t.id === tipoPieza)?.nombre || "Imagen";
      const nombreTipoPiezaFormatted = nombreTipoPieza
        .replace(/\s+/g, "_")
        .toLowerCase();

      // Añadir proxy para evitar CORS
      const proxyUrl = "https://corsproxy.io/?";
      const imageUrl = proxyUrl + url;

      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const urlBlob = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = urlBlob;
        link.setAttribute(
          "download",
          `${nombreTipoPiezaFormatted}_${index + 1}.jpg`
        );

        document.body.appendChild(link);
        link.click();

        URL.revokeObjectURL(urlBlob);
        document.body.removeChild(link);
      } catch (e) {
        toast.error(e.message);
      }
    };

    try {
      setIsDownloading(true);
      const downloadPromises = pieza.imagenes.map((imgUrl, i) =>
        downloadImage(imgUrl, i)
      );
      await Promise.all(downloadPromises);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsDownloading(false);
    }
  };

  const getTarifaValor = (precioCompra, tarifaId) => {
    const tarifa = tarifas.find((f) => f.id === tarifaId);
    const valor = tarifa?.valores.find((f) => {
      if (f.max) {
        return (
          parseInt(precioCompra) <= f.max && parseInt(precioCompra) >= f.min
        );
      } else return parseInt(precioCompra) >= f.min;
    });

    return valor;
  };

  const handleChangeContieneCasco = (e) => {
    const value = e.target.value;
    setValue("contiene_casco", value);

    checkIfContieneCascoObligatio();
  };

  const handleChangeMarca = (e) => {
    const filteredModelosByMarcaId = modelos.filter((f) => {
      return f.marca_id === parseInt(e.target.value);
    });
    const firstModeloId = filteredModelosByMarcaId[0];
    setFilteredModelos([...filteredModelosByMarcaId]);
    setValue("modelo", firstModeloId);
    setValue("modelo_id", firstModeloId.id);
  };

  const handleChangeModelo = (value) => {
    if (value) {
      setValue("modelo", value);
      setValue("modelo_id", value.id);
    } else {
      setValue("modelo", null);
      setValue("modelo_id", 0);
    }
  };

  const handleChangeFamilia = (e) => {
    const filteredTipoPiezas = tipoPiezas.filter(
      (tipoPieza) =>
        tipoPieza.familia_tipo_pieza_id === parseInt(e.target.value)
    );
    setFilteredTipoPiezas([...filteredTipoPiezas]);
  };

  const handleChangeTipo = (e) => {
    const targetValue = parseInt(e.target.value);

    if (proveedor) {
      const isBloqued = checkAndCreateBloqueoLog(proveedor, targetValue);

      if (isBloqued) {
        onBlockedPart(proveedor, targetValue);
        return;
      }
    }

    setValue("precio_transporte", calcularPrecioEnvio(targetValue));
    setValue("tipo_garantia_id", getGarantia(targetValue));

    checkIfContieneCascoObligatio();
  };

  const handleCalcularPrecios = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      const precios = calcularPrecios(getValues());

      setValue("precio_compra", precios.compra);
      setValue("descuento", precios.descuento);
      setValue("tarifa_valor", precios.tarifa.valor);
      setValue("tarifa_tipo", precios.tarifa.tipo);

      if (proveedor.id !== 409) {
        setValue("precio_venta", precios.venta);
      } else {
        setValue("precio_venta", getValues("precio_original"));
      }
    }
  };

  const calcularPrecios = (pieza) => {
    const original = pieza.precio_original;
    let venta = original;
    let compra = original;
    let tarifaId;

    //primero aplicamos el descuento
    let descuentosProveedor;
    let descuento = null;

    if (pieza.con_descuento) {
      descuentosProveedor =
        proveedor?.descuentos?.length > 0
          ? proveedor?.descuentos.sort(
              (a, b) => b.tipo_piezas?.length - a?.tipo_piezas.length
            )
          : proveedor?.descuentos;
      descuento = descuentosProveedor?.find((f) => {
        let isIn = true;

        if (f.tipo_piezas && f.tipo_piezas.length > 0) {
          let tipo = tipoPiezas.find(
            (tp) => tp.id === parseInt(pieza.tipo_pieza_id)
          );
          isIn = tipo
            ? f.tipo_piezas.find((f) => f.id === parseInt(pieza.tipo_pieza_id))
            : false;
        }

        if (f.max !== null && f.min !== null) {
          return (
            parseInt(original) <= f.max && parseInt(original) >= f.min && isIn
          );
        } else if (f.min !== null && f.max === null) {
          return parseInt(original) >= f.min && isIn;
        } else if (f.max !== null && f.min === null) {
          return parseInt(original) <= f.max && isIn;
        } else return true;
      });

      if (descuento) descuento = descuento.descuento;
    }

    if (descuento) {
      let descuentoCompra = (descuento * parseFloat(original)) / 100;
      compra = parseFloat(original) - descuentoCompra;
      setValue("descuento", descuento);
    } else descuento = 0;

    // ahora calculamos la tarifa
    if (!tarifaClienteId) {
      tarifaId = presupuesto.cliente?.tarifa_cliente_id;
    } else tarifaId = tarifaClienteId;

    const valor = getTarifaValor(compra, tarifaId);

    if (valor) {
      if (valor.tipo === "porcentage") {
        let porcentageValue =
          "1." + (valor.valor < 10 ? "0" + valor.valor : valor.valor);
        porcentageValue = parseFloat(porcentageValue);
        venta = parseFloat(compra) * porcentageValue;
      } else {
        venta = parseFloat(compra) + valor.valor;
      }
    }

    if (descuento === 20) {
      if (compra <= 60) venta = original;
    }

    compra = parseFloat(compra).toFixed(2);
    venta = parseFloat(venta).toFixed(2);

    return {
      compra: compra,
      venta: venta,
      descuento: descuento,
      tarifa: { valor: valor?.valor, tipo: valor?.tipo },
    };
  };

  const calcularPrecioEnvio = (tipoPieza) => {
    const envio = tarifasEnvioProveedor.find((tarifa) =>
      tarifa.tipo_piezas.split(",").some((tp) => parseInt(tp) === tipoPieza)
    );
    return envio?.precio || 0;
  };

  const handleChangePrecioTransporte = (e) => {
    const precioTransporte = e.target.value;
    setValue("precio_transporte", precioTransporte);

    if (pieza?.servicio_envio) {
      if (
        pieza.servicio_envio.transportista_id === 1 &&
        (pieza.servicio_envio.servicio_envio_id === 1 ||
          pieza.servicio_envio.servicio_envio_id === 6)
      ) {
        onChangePrecioEnvioProveedor(getValues());
      }
    }
  };

  const startTimeOut = (e) =>
    (timeout = setTimeout(() => {
      timeout = null;
      handleChangePrecioTransporte(e);
    }, 600));
  const clearTimeOut = () => (timeout ? clearTimeout(timeout) : null);

  const handleCloseAvisoDifPrice = () => setShowDifPrice(false);

  const handleSubmitPieza = async (data) => {
    delete data.imagenes;

    if (data.precio_venta < data.precio_compra && user?.rol_id === 2) {
      setShowDifPrice(true);
      return;
    }

    data.modelo_id = data.modelo.id;
    delete data.modelo;

    onSubmitPieza(data);
  };

  /** Render */
  return (
    <div className="w-100 d-flex flex-column justify-content-center mt-2">
      <form className="form" onSubmit={handleSubmit(handleSubmitPieza)}>
        <div className="w-100 d-flex flex-wrap flex-md-nowrap">
          <div className="col-12 col-md-6 d-flex flex-column pe-0 pe-md-3">
            <div className="d-flex align-items-center">
              <div className="form-group">
                <label htmlFor="url">URL</label>
                <input
                  type="text"
                  className="form-control"
                  {...register("url", { required: true, maxLength: 255 })}
                  disabled={isVenta}
                  maxLength="255"
                  defaultValue={isEditing ? pieza?.url : ""}
                />
                <div className="form-invalid">
                  {errors.url?.message && "La URL es obligatoria"}
                </div>
              </div>
              <button
                className="btn btn-primary ms-3 mt-1 col-4 d-flex align-items-center justify-content-center"
                onClick={() => handleClickViewImages(true)}
                type="button"
              >
                Ver Imágenes
              </button>
              <button
                className="btn btn-primary ms-3 mt-1 d-flex align-items-center justify-content-center"
                style={{ width: "42px", height: "42px", position: "relative" }}
                onClick={!isDownloading ? handleDownloadImages : undefined}
                type="button"
              >
                {isDownloading ? (
                  <CircularProgress
                    sx={{ color: "white", position: "absolute" }}
                    size={16}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faDownload}
                    color="#fff"
                    className="icon"
                    style={{ margin: "auto" }}
                  />
                )}
              </button>
            </div>

            <div className="d-flex align-items-center">
              <div className="form-group">
                <label htmlFor="nombre">Nombre</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={isVenta}
                  {...register("nombre", { required: true, maxLength: 150 })}
                  defaultValue={isEditing ? pieza?.nombre : ""}
                />
                <div className="form-invalid">
                  {errors.nombre?.message && "Nombre es obligatorio"}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-lg-row align-items-end">
              <div className="form-group me-0 pe-lg-3">
                <label htmlFor="unidades">Unidades</label>
                <input
                  type="number"
                  className="form-control"
                  {...register("unidades", { required: true })}
                  disabled={isVenta}
                  defaultValue={
                    isEditing && presupuesto?.id
                      ? pieza.presupuesto_relationship.unidades
                      : pieza?.unidades || 1
                  }
                />
                <div className="form-invalid">
                  {errors.unidades?.message && "Unidades es obligatorio"}
                </div>
              </div>

              <div className="form-group flex-grow-1">
                <label htmlFor="precio_transporte">Casco (sin IVA)</label>
                <div className="d-flex align-items-center">
                  <select
                    style={
                      watch("contiene_casco") === "true"
                        ? { width: "auto" }
                        : {}
                    }
                    {...register("contiene_casco")}
                    defaultValue={isEditing ? pieza?.contiene_casco : "false"}
                    disabled={isVenta}
                    onChange={handleChangeContieneCasco}
                  >
                    <option value="">Selecciona</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                  {(watch("contiene_casco") === "true" ||
                    watch("contiene_casco") === true) && (
                    <div className="position-relative flex-grow-1 ms-2">
                      <input
                        type="number"
                        step="0.01"
                        className="form-control"
                        disabled={isVenta}
                        {...register("casco")}
                        min={0}
                        defaultValue={
                          isEditing
                            ? pieza?.casco !== null
                              ? pieza?.casco
                              : 0
                            : 0
                        }
                      />
                      <FontAwesomeIcon
                        icon={faEuroSign}
                        color="#215732"
                        className="icon"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-lg-row align-items-end">
              <div className="form-group me-0 pe-lg-3">
                <label htmlFor="id_referencia_plataforma">
                  ID Referencia plataforma
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={isVenta}
                  {...register("id_referencia_plataforma", {
                    required: true,
                    maxLength: 50,
                  })}
                  defaultValue={
                    isEditing ? pieza?.id_referencia_plataforma : ""
                  }
                />
                <div className="form-invalid">
                  {errors.id_referencia_plataforma?.message &&
                    "ID Referencia Plataforma es obligatorio"}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="referencia_pieza">Referencia pieza</label>
                <input
                  type="text"
                  className="form-control"
                  disabled={isVenta}
                  {...register("referencia_pieza", {
                    required: true,
                    maxLength: 50,
                  })}
                  defaultValue={isEditing ? pieza?.referencia_pieza : ""}
                />
                <div className="form-invalid">
                  {errors.referencia_pieza?.message &&
                    "Obligatorio poner la referencia correcta de la pieza para construir una base de datos fiable."}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-lg-row align-items-end">
              <div className="form-group me-0 pe-lg-3">
                <label htmlFor="marca_id">Marca</label>
                {marcas && marcas.length > 0 && (
                  <select
                    {...register("marca_id")}
                    defaultValue={isEditing ? pieza?.marca_id : ""}
                    disabled={isVenta}
                    onChange={handleChangeMarca}
                  >
                    {marcas.map((op) => (
                      <option key={"marcas-" + op.id} value={op.id}>
                        {op.nombre}
                      </option>
                    ))}
                  </select>
                )}
                <div className="form-invalid">
                  {errors.marca_id?.message && "Selecciona una marca válida."}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="modelo_id">Modelo</label>
                <input
                  type="hidden"
                  {...register("modelo_id")}
                  defaultValue={isEditing ? pieza?.modelo_id : 0}
                />
                {filteredModelos.length > 0 && (
                  <VirtualizedAutocomplete
                    id={"modelo"}
                    control={control}
                    options={filteredModelos.sort(
                      (a, b) => a.marca_id - b.marca_id
                    )}
                    groupBy={"marca"}
                    disabled={isVenta}
                    labels={["marca", "nombre"]}
                    defaultValue={isEditing ? pieza?.modelo : null}
                    onChange={handleChangeModelo}
                  />
                )}
                <div className="form-invalid">
                  {errors.modelo_id?.message && "Selecciona una modelo válido."}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 d-flex flex-column ps-0 ps-md-3">
            <div className="d-flex flex-column flex-lg-row align-items-center">
              <div className="form-group flex-grow-1 me-0 pe-lg-3">
                <label htmlFor="familia_tipo_pieza_id">Familia</label>
                {familiaPiezas && familiaPiezas.length > 0 && (
                  <select
                    {...register("familia_tipo_pieza_id")}
                    disabled={isVenta}
                    defaultValue={isEditing ? pieza?.familia_tipo_pieza_id : 1}
                    onChange={handleChangeFamilia}
                  >
                    {familiaPiezas
                      .sort((a, b) => {
                        return a.nombre.localeCompare(b.nombre);
                      })
                      .map((op) => (
                        <option key={"familias-" + op.id} value={op.id}>
                          {op.nombre}
                        </option>
                      ))}
                  </select>
                )}
              </div>

              <div className="form-group flex-grow-1">
                <label htmlFor="tipo_pieza_id">Tipo de pieza</label>
                {filteredTipoPiezas && (
                  <select
                    {...register("tipo_pieza_id")}
                    defaultValue={
                      isEditing
                        ? pieza?.tipo_pieza_id
                        : filteredTipoPiezas[0]?.id
                    }
                    disabled={isVenta}
                    onChange={handleChangeTipo}
                  >
                    {filteredTipoPiezas
                      .sort((a, b) => {
                        return a.nombre.localeCompare(b.nombre);
                      })
                      .map((op) => (
                        <option key={"tipos-" + op.id} value={op.id}>
                          {op.nombre}
                        </option>
                      ))}
                  </select>
                )}
                <div className="form-invalid">
                  {errors.tipo_pieza_id?.message &&
                    "Selecciona un tipo de pieza válido."}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-lg-row align-items-end">
              <div className="form-group flex-grow-1 me-0 pe-lg-3">
                <label htmlFor="descuento">Descuento Proveedor</label>
                <div className="position-relative">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    {...register("descuento")}
                  />
                  <span className="text-success icon">
                    <b>%</b>
                  </span>
                </div>
              </div>

              <div className="form-group flex-grow-1">
                <label htmlFor="tipo_garantia_id">Tipo de garantía</label>
                {tipoGarantias && (
                  <select
                    {...register("tipo_garantia_id")}
                    defaultValue={isEditing ? pieza?.tipo_garantia_id : 1}
                    disabled={isVenta}
                  >
                    {tipoGarantias.map((op) => (
                      <option key={"garantias-" + op.id} value={op.id}>
                        {op.duracion}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>

            <div className="d-flex flex-column flex-lg-row align-items-end">
              <div className="form-group flex-grow-1">
                <label htmlFor="km_motor">KM motor</label>
                <input
                  type="number"
                  className="form-control"
                  step="0.001"
                  min={0}
                  disabled={isVenta}
                  {...register("km_motor")}
                  defaultValue={isEditing ? pieza?.km_motor : 0}
                />
              </div>
            </div>

            <div className="d-flex flex-column flex-lg-row align-items-end">
              <div className="form-group flex-grow-1 me-0 pe-lg-3">
                <label htmlFor="precio_original">Precio original</label>
                <div className="position-relative">
                  <input
                    type="number"
                    step="0.01"
                    className="form-control"
                    min={0}
                    disabled={disablePrecioOriginal}
                    {...register("precio_original", { required: true })}
                    defaultValue={isEditing ? pieza?.precio_original : 0}
                  />
                  <FontAwesomeIcon
                    icon={faEuroSign}
                    color="#215732"
                    className="icon"
                  />
                </div>
                <div className="form-invalid">
                  {errors.precio_original?.message &&
                    "Precio original es obligatorio y tiene que ser más de 0"}
                </div>
              </div>

              <div className="form-group flex-grow-1">
                <label htmlFor="precio_compra">Precio compra</label>
                <div className="position-relative">
                  <input
                    type="number"
                    step="0.01"
                    className="form-control"
                    min={0}
                    disabled={
                      isVenta &&
                      !(currentUser?.rol_id === 1 || currentUser?.rol_id === 4)
                    }
                    onKeyDown={isVenta ? null : handleCalcularPrecios}
                    {...register("precio_compra", { required: true })}
                    defaultValue={isEditing ? pieza?.precio_compra : 0}
                  />
                  <FontAwesomeIcon
                    icon={faEuroSign}
                    color="#215732"
                    className="icon"
                  />
                </div>
                <div className="form-invalid">
                  {errors.precio_compra?.message &&
                    "Precio compra es obligatorio y tiene que ser más de 0"}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column flex-lg-row align-items-end">
              <div className="form-group flex-grow-1 me-0 pe-lg-3">
                <label htmlFor="precio_venta">Precio venta (sin IVA)</label>
                <div className="position-relative">
                  <input
                    type="number"
                    step="0.01"
                    className="form-control"
                    min={0}
                    disabled={disablePrecioVenta}
                    {...register("precio_venta", { required: true })}
                    defaultValue={isEditing ? pieza?.precio_venta : 0}
                  />
                  <FontAwesomeIcon
                    icon={faEuroSign}
                    color="#215732"
                    className="icon"
                  />
                </div>
                <div className="form-invalid">
                  {errors.precio_venta?.message &&
                    "Precio venta es obligatorio y tiene que ser más de 0"}
                </div>
              </div>

              <div className="form-group flex-grow-1">
                <label htmlFor="precio_transporte">Transporte (sin IVA)</label>
                <div className="position-relative">
                  <input
                    type="number"
                    step="0.01"
                    className="form-control"
                    disabled={isVenta}
                    {...register("precio_transporte", { required: true })}
                    min={0}
                    onKeyUp={(e) => {
                      e.preventDefault();
                      clearTimeOut();
                      startTimeOut(e);
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faEuroSign}
                    color="#215732"
                    className="icon"
                  />
                </div>
                <div className="form-invalid">
                  {errors.precio_transporte?.message &&
                    "Precio transporte es obligatorio y tiene que ser positivo"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 d-flex align-items-center">
          <div className="form-group flex-grow-1">
            <label htmlFor="observaciones">Observaciones</label>
            <textarea
              disabled={isVenta}
              {...register("observaciones")}
              rows={5}
              defaultValue={isEditing ? pieza?.observaciones : ""}
            ></textarea>
          </div>
        </div>

        <div className="d-flex align-items-center align-self-end">
          <button
            type="submit"
            className="btn btn-primary align-self-end"
            disabled={!servicioEnvio ? true : false}
          >
            Guardar
          </button>
        </div>
      </form>

      {showDifPrice && (
        <InfoModal
          state={showDifPrice}
          title={<span className="text-danger fw-bold">¡ERROR!</span>}
          content={
            <p className="text-center text-danger fw-bold">
              El precio de venta no puede ser inferior al de compra.
            </p>
          }
          width={"sm"}
          onClose={handleCloseAvisoDifPrice}
        ></InfoModal>
      )}

      <Dialog
        open={imagesDialogVisible}
        onClose={() => handleClickViewImages(false)}
      >
        <div style={{ height: "600px", width: "600px" }}>
          <LightBoxImages
            images={pieza.imagenes}
            startIndex={0}
            pieza={pieza}
            onClose={() => handleClickViewImages(false)}
          />
        </div>
      </Dialog>
    </div>
  );
}
